import './Header.css';

function Header(props) {
    return (
        <header>
            <h1 className="heading gradient-text">
                <a>
                    CryptoBalloonerz
                </a>
            </h1>
            <h2 className='subheading'>
                3141 hot Ar(weave) balloon drawings unleashed on the Ethereum network by the&nbsp;
                <a href='https://mirror.xyz/0x2FadbE50e4499B546561c64E50799B378a46e073' target='_blank' rel='noreferrer'>Wolf Brothers</a>
                .
            </h2>
            <div>
                <button className='os-button'>
                    <a href={props.opensea} target='_blank' rel='noreferrer'>
                        View Collection on OpenSea
                    </a>
                </button>
            </div>
        </header>
    )
}

export default Header;