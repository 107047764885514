import './Footer.css';
import twitter_white from '../assets/twitter.svg';
import discord from '../assets/discord.svg'

function Footer(props) {
    return (
        <footer className='footer'>
            <p>
                SMART CONTRACT ADDRESS:&nbsp;
                <br />
                <span>
                    <a className='contract-link' href={`https://etherscan.io/address/${props.address}`} target='_blank' rel='noreferrer'>
                        {props.address}
                    </a>
                </span>
            </p>
            <div className='footer-social-media-links'>
                <div>
                    <a href='https://twitter.com/_wolfbrothers_' target='_blank' rel='noreferrer'>
                        <img src={twitter_white} alt="Twitter" width="7%"/>
                    </a> <a href='https://discord.gg/aJ9uXP9gyB' target='_blank' rel='noreferrer'>
                        <img src={discord} alt="Twitter" width="7%"/>
                    </a>
                </div>
            </div>
        </footer>
    )
}

export default Footer;