import './App.css';
import React, { useEffect, useState } from "react";
import cryptoballoonerzImg from './assets/cryptoballoonerz.gif';
import { ethers } from 'ethers';
import contract from './contracts/CryptoBalloonerz.json';
import { Fragment } from 'react/cjs/react.production.min';
import Footer from './components/Footer';
import Header from './components/Header';

// Constants
const OPENSEA_LINK = 'https://opensea.io/collection/cryptoballoonerz';
const MIRROR_XYZ_LINK = 'https://mirror.xyz/0x2FadbE50e4499B546561c64E50799B378a46e073';
const DISCORD_LINK = 'https://discord.gg/aJ9uXP9gyB'
const contractAddress = "0xB5dFA399Dc4a3BfEB196395746f82fA089D788E1";
const abi = contract.abi;
const maxNFTs = 2;
const minNFTs = 1;
const maxCount = 3141;
const tokenValue = 0.003141;

const App = () => {

  const [currentAccount, setCurrentAccount] = useState(null);
  const [metamaskError, setMetamaskError] = useState(null);
  const [mineStatus, setMineStatus] = useState(null);
  const [counter, setCounter] = useState(minNFTs);
  const [nftCounter, setNFTCounter] = useState(0);

  const incrementCounter = () => setCounter(counter + 1);
  const decrementCounter = () => setCounter(counter - 1);
  if(counter>maxNFTs) {
    setCounter(maxNFTs);
  }
  if(counter<minNFTs){
    setCounter(minNFTs);
  }

  const setChain = async () => {
    const { ethereum } = window;
    try {
      // check if the chain to connect to is installed
      await ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: '0x1' }], // chainId must be in hexadecimal numbers
      });
    } catch (error) {
      // This error code indicates that the chain has not been added to MetaMask
      // if it is not, then install it into the user MetaMask
      if (error.code === 4902) {
          try {
          await ethereum.request({
              method: 'wallet_addEthereumChain',
              params: [
              {
                  chainId: '0x1',
                  rpcUrl: 'https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161',
              },
              ],
          });
          } catch (addError) {
              console.error(addError);
          }
      }
      console.error(error);
    }
  }

  const checkWalletIsConnected = async () => {
    const { ethereum } = window;

    if (!ethereum) {
      console.log("Make sure you have Metamask installed!");
      return;
    } else {
      console.log("Wallet exists! We're ready to go!")
    }

    const accounts = await ethereum.request({ method: 'eth_accounts' });
    const network = await ethereum.request({ method: 'eth_chainId' });

    if (accounts.length !== 0) {
      const account = accounts[0];
      console.log("Found an authorized account: ", account);
      // Set chain
      if (network.toString() !== '0x1'){
        setChain();
      }
      setMetamaskError(false);
      setCurrentAccount(account);
      getProviderAndSetCounter(ethereum);
      //setupEventListener();
    } else {
      setMetamaskError(true);
      console.log("No authorized account found");
    }
  }

  const connectWallet = async () => {
    const { ethereum } = window;

    if (!ethereum) {
      alert("Please install Metamask!");
    }

    try {
      const network = await ethereum.request({ method: 'eth_chainId' });
      const accounts = await ethereum.request({ method: 'eth_requestAccounts' });
      console.log("Found an account! Address: ", accounts[0]);
      if (network.toString() !== '0x1') {
        setChain();
      }
      setMetamaskError(false);
      setCurrentAccount(accounts[0]);
      getProviderAndSetCounter(ethereum);

    } catch (err) {
      setMetamaskError(true);
      console.log(err)
    }
  }

  const getProviderAndSetCounter = async (ethereum) => {
    if (nftCounter == maxCount){
      setMineStatus('minting-finished');
    }
    const provider = new ethers.providers.Web3Provider(ethereum);
    const signer = provider.getSigner();
    const nftContract = new ethers.Contract(contractAddress, abi, signer);
    setNFTCounter(await nftContract.totalSupply());
  }

  const mintNFT = async () => {
    try {
      const { ethereum } = window;
      if (ethereum) {
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const nftContract = new ethers.Contract(contractAddress, abi, signer);
        const network = await ethereum.request({ method: 'eth_chainId' });
        if (network.toString() !== '0x1') {
          setChain();
        }
        console.log("Initialize payment");

        let nftTxn;
        console.log(await nftContract.isWhitelisted(currentAccount));
        if (await nftContract.isWhitelisted(currentAccount)){
          console.log("Whitelisted case");
          nftTxn = await nftContract.mint(counter);
        } else {
          console.log("Non-whitelisted case");
          let txValue = tokenValue * counter;
          nftTxn = await nftContract.mint(counter, {value: ethers.utils.parseEther(txValue.toString()) });
        }
        setMineStatus('mining');
        console.log("Mining... please wait");
        await nftTxn.wait();

        console.log(`Mined, see transaction: https://etherscan.io/tx/${nftTxn.hash}`);
        setMineStatus('success');
        setNFTCounter(await nftContract.totalSupply());
        
      } else {
        setMineStatus('error');
        console.log("Ethereum object does not exist");
      }
    } catch (err) {
      setMineStatus('error');
      console.log(err);
    }
  }

  useEffect(() => {
    checkWalletIsConnected();

    if (window.ethereum) {
      window.ethereum.on('chainChanged', (_chainId) => window.location.reload());
    }
  }, [])

  // Render Methods
  const renderNotConnectedContainer = () => (
    <button onClick={connectWallet} className="cta-button connect-wallet-button">
      Connect to Wallet
    </button>
  );

  const renderMintUI = () => {     
    if (mineStatus ===  "minting-finished"){
      return (
        <div className="mintCounter">
          Mint count: {nftCounter.toString()} / {maxCount} &nbsp;&nbsp;
          <progress value={nftCounter.toString()} max={maxCount.toString()} className="progress-bar"></progress>
          <br></br>
        </div>
      );
    }else{
      return (
        <>
        <div>
            <button onClick={decrementCounter} className="counter decrement-button">
            -
            </button >
            <button onClick={incrementCounter} className="counter increment-button">
            +
            </button >
            <label style={{ marginLeft: '.5rem'}} className="counter_display">{"Mint amount: " + counter.toString()}</label>
        </div>
        <button onClick={mintNFT} className="cta-button mint-button" >
          Take off!
        </button >
        <div className="mintCounter">
          Mint count: {nftCounter.toString()} / {maxCount} &nbsp;&nbsp;
          <progress value={nftCounter.toString()} max={maxCount.toString()} className="progress-bar"></progress>
          <br></br>
        </div>
        </>
      );
    }
  }

function updateDisplay(val) {
    document.getElementById("counter-label").innerHTML = val;
}

  return (
    <Fragment>
      {metamaskError && <div className='metamask-error'>Please make sure you are connected to the Ethereum Network on Metamask!</div>}
      <div className="App">
        <div className="container">
          <Header opensea={OPENSEA_LINK} />
          <div className="header-container">
            <div className='banner-img'>
              <img src={cryptoballoonerzImg} alt="CryptoBalloonerz" />
            </div>
            {currentAccount && mineStatus !== 'mining' && renderMintUI()}
            {!currentAccount && !mineStatus && renderNotConnectedContainer()}
            <div className='mine-submission'>
              {mineStatus === 'success' && <div className={mineStatus}>
                <p>Congratulations! Minting was successful!</p>
                <p className='success-link'>
                  <a href={`https://opensea.io/${currentAccount}/`} target='_blank' rel='noreferrer'>Click here</a>
                  <span> to view your balloon(z) on OpenSea.</span>
                </p>
              </div>}
              {mineStatus === 'mining' && <div className={mineStatus}>
                <div className='loader' />
                <span>Minting, please wait...</span>
              </div>}
              {mineStatus === 'error' && <div className={mineStatus}>
                <p>Transaction failed. Make sure you have at least {tokenValue} Ethereum in your wallet and try again.</p>
              </div>}
              {mineStatus === 'minting-finished' && <div className={mineStatus}>
                <p>Sorry! No more NFTs left!</p>
              </div>}
            </div>
          </div>
          {mineStatus !== 'mining' && mineStatus !== 'success' && <div className='guide'>
          </div>}
          {<p className='guide p'>
            Minting is now open, the price of each balloon is 0.003141 ETH. Maximum 2 mints available in each transaction.
          </p>
          }
          {<p className='guide p'>Check out our project on &nbsp;
              <a href={MIRROR_XYZ_LINK} target='_blank' rel='noreferrer'>
                 mirror.xyz
              </a>!
          </p>
          }
          {<p className='guide p'>Land on our <a href={DISCORD_LINK} target='_blank' rel='noreferrer'>
              Discord
              </a> with your newly minted balloon!
          </p>
          }
          <Footer address={contractAddress} />
        </div>
      </div>
    </Fragment>
  );
};

export default App;
